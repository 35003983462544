const submenuShadow = () => {
  const SHADOW_CLASS_NAME = "layout__main-subheader--shadow";
  const subHeaderElement = document.getElementById("layout-subheader");

  const toggleShadow = () => {
    const displayShadow = document.body.scrollTop > 0;
    subHeaderElement.classList.toggle(SHADOW_CLASS_NAME, displayShadow);
  };

  const initializeShadow = () => {
    window.onscroll = () => toggleShadow();
    toggleShadow();
  };

  if (subHeaderElement !== null) {
    initializeShadow();
  }
};

export default submenuShadow;
