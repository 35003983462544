const sidebarMenuDynamicWidth = () => {
  const SIDEBAR_SELECTOR = '.layout__main-sidebar';
  const SIDEBAR_ITEM_SELECTOR = '.nav-sidebar > li > a';
  const SHRINKED_CLASS = 'shrinked';

  $(window).on('resize load', function () {
    const sidebar = $(SIDEBAR_SELECTOR)[0];
    $(SIDEBAR_ITEM_SELECTOR).toggleClass(SHRINKED_CLASS, sidebar && sidebar.scrollHeight > sidebar.clientHeight);
  });
};

export default sidebarMenuDynamicWidth;