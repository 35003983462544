import $ from "jquery";

/**
 * Query parameter that is eventually forwarded to login controller, in which is used for printing specific messages.
 * @type {string}
 */
const LOGOUT_TIMEOUT_SUFFIX = "?timeout";
const FORBIDDEN_HTTP_CODE = 403;

/**
 * XHR error handler that will check the http status of failed ajax response and in case of redirect to login page or forbidden status will redirect the window to logout page
 */
export default class AjaxErrorHandler {

  constructor(logoutUrl) {
    this.logoutUrl = logoutUrl;
  }

  init() {
    $(document).ajaxError((event, jqXHR, ajaxSettings, thrownError) => {
      this.handleErroneousAjaxResponse(jqXHR);
    });
  }

  handleErroneousAjaxResponse(xhr) {
    if (xhr.status === FORBIDDEN_HTTP_CODE) {
      this.redirectToLogin();
    }
  }

  redirectToLogin() {
    window.location = this.logoutUrl + LOGOUT_TIMEOUT_SUFFIX;
  }
}