import JavaScriptErrorHandler from "../../../web-base/layout/es6-bundle/modules/javascript-error-handler";
import csrfInitialization from "../../../web-base/layout/es6-bundle/modules/csrf";
import submenuShadow from "../../../web-base/layout/es6-bundle/modules/submenu-shadow";
import AjaxErrorHandler from "../../../web-base/layout/es6-bundle/modules/ajax-error-handler";
import passwordInputClear from "../../../web-base/layout/es6-bundle/modules/password-input-clear";
import sidebarMenuDynamicWidth from "../../../web-base/layout/es6-bundle/modules/sidebar-menu-dynamic-width";

csrfInitialization();
submenuShadow();
passwordInputClear();
sidebarMenuDynamicWidth();

window.JavaScriptErrorHandler = JavaScriptErrorHandler;
window.AjaxErrorHandler = AjaxErrorHandler;

export default window;
